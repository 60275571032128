<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-3"
        ref="container"
        style="height:100%"
    >
        <v-progress-linear v-if="loading" indeterminate color="primary" />
        <v-data-table
            v-else
            id="virtual-scroll-table"
            disable-pagination
            hide-default-footer
            :headers="headers"
            :items="filteredForecasts"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            :style="`height: ${height}px`"
            @click:row="openProject"
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="9">
                        <h2 class="ml-4">PROJECTS FORECAST</h2>
                        <v-spacer />
                    </v-col>
                    <v-col cols="3">
                        <v-btn-toggle style="width: 100%;">
                            <v-btn
                                @click.stop="showYears()"
                                rounded
                                small
                                style="width: 50%"
                                >YEARS</v-btn
                            >
                            <v-btn
                                @click.stop="showMonths"
                                rounded
                                small
                                style="width: 50%"
                                >MONTHS</v-btn
                            >
                        </v-btn-toggle>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.projectManager`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="projectManagerToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.client`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="clientToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.forecastedPercentage`]="{ header }">
                <v-text-field
                    class="py-2 mt-2 custom-label"
                    :label="header.text"
                    v-model="forecastedPercentageToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{ item.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.projectManager`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize" v-if="users.length > 0">
                        {{ setUserName(item.projectManager) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.client`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize" v-if="users.length > 0">
                        {{ setClientName(item.client) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.lastUpdate`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{ setLastUpdate(item) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.projectPrice`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{ formatCurrency(item.currency, item.price || 0) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.balanceToBill`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{
                            formatCurrency(
                                item.currency,
                                (item.price || 0) - (item.totalInvoiced || 0)
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.baseBalanceToBill`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize">
                        {{
                            formatCurrency(
                                item.currency,
                                setProjectBalanceToBill(item)
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisMonth`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            formatCurrency(
                                item.currency,
                                item.invoicedThisMonth
                            )
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.invoicedThisYear`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            formatCurrency(item.currency, item.invoicedThisYear)
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actualVsForecasted`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0 text-capitalize d-flex justify-end">
                        {{
                            viewType == 'month'
                                ? getDateAmount(item, currentDate) > 0
                                    ? (
                                          (item.invoicedThisMonth * 100) /
                                          getDateAmount(item, currentDate)
                                      ).toFixed(1)
                                    : '-'
                                : getDateAmount(
                                      item,
                                      currentDate.split('-')[0]
                                  ) > 0
                                ? (
                                      (item.invoicedThisYear * 100) /
                                      getDateAmount(
                                          item,
                                          currentDate.split('-')[0]
                                      )
                                  ).toFixed(1)
                                : '-'
                        }}
                        %
                    </p>
                </div>
            </template>
            <template v-slot:[`item.forecastedPercentage`]="{ item }">
                <div class="d-flex justify-center">
                    <p
                        class="my-0 text-capitalize"
                        :class="{
                            'text-orange': item.forecastedPercentage < 100,
                            'text-green': item.forecastedPercentage == 100,
                            'text-red': item.forecastedPercentage > 100,
                        }"
                    >
                        {{ item.forecastedPercentage }}%
                    </p>
                </div>
            </template>
            <template v-slot:[`item.date`]="{ item, header }">
                <v-col>
                    <p
                        class="my-1"
                        v-if="getDatePercentage(item, header.month) > 0"
                    >
                        {{ getDatePercentage(item, header.month) }}
                        %
                    </p>
                    <p
                        class="mb-1"
                        v-if="getDateAmount(item, header.month) > 0"
                    >
                        {{
                            formatCurrency(
                                item.currency,
                                getDateAmount(item, header.month)
                            )
                        }}
                    </p>
                </v-col>
            </template>
            <template v-slot:[`item.dateYear`]="{ item, header }">
                <v-col>
                    <p
                        class="my-1"
                        v-if="getDatePercentage(item, header.year) > 0"
                    >
                        {{ getDatePercentage(item, header.year) }}
                        %
                    </p>
                    <p class="mb-1" v-if="getDateAmount(item, header.year) > 0">
                        {{
                            formatCurrency(
                                item.currency,
                                getDateAmount(item, header.year)
                            )
                        }}
                    </p>
                </v-col>
            </template>
            <!--FOOTER-->
            <template v-slot:[`body.append`]>
                <tr style="background-color: #eeeeee">
                    <td class="font-weight-bold"></td>
                    <td class="font-weight-bold">
                        TOTALS
                    </td>
                    <td class="font-weight-bold"></td>
                    <td class="font-weight-bold"></td>
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-center my-0">
                            {{ totalProjectsPrice() }}
                        </p>
                    </td>
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-center my-0">
                            {{ totalProjectsB2B() }}
                        </p>
                    </td>
                    <td class="font-weight-bold">
                        <p class="d-flex justify-end align-center my-0">
                            {{ totalProjectsBaseB2B() }}
                        </p>
                    </td>
                    <td class="font-weight-bold"></td>
                    <td class="font-weight-bold"></td>
                    <td class="font-weight-bold"></td>
                    <td
                        class="mt-4 ml-1"
                        v-for="(header, index) in headers.slice(10)"
                        :key="index"
                    >
                        <p class="d-flex justify-end">
                            {{ totalPerDate(currentDate) }}
                        </p>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <!-- project  -->
        <v-dialog
            :retain-focus="false"
            v-model="detailView"
            fullscreen
            ref="dialogQuotesBySection"
        >
            <v-card class="fill-height" v-if="detailView">
                <v-card-title class="ml-n4">
                    <v-btn icon @click="closeDetailView">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Forecast Details - {{ selectedProject.name }}
                </v-card-title>
                <v-card-text class="px-0">
                    <B2BForecast
                        class="px-6"
                        v-if="detailView"
                        :projectId="selectedProject.id"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import _ from 'lodash'

export default {
    name: 'Forecast',
    components: {
        B2BForecast: () => import('@/components/Forecast/B2BForecast.vue'),
    },
    data() {
        return {
            height: 0,
            dateToShow: [],
            hideMonths: false,
            originalHeaders: [
                {
                    text: 'PROJECT',
                    value: 'project',
                    align: 'center',
                    sortable: false,
                    width: 200,
                    class: 'gray-background',
                },
                {
                    text: 'PROJECT MANAGER',
                    value: 'projectManager',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 180,
                },
                {
                    text: 'CLIENT',
                    value: 'client',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                },
                {
                    text: 'LAST UPDATE',
                    value: 'lastUpdate',
                    align: 'center',
                    sortable: false,
                    class: 'gray-background',
                    width: 120,
                },
                {
                    text: 'PROJECT PRICE',
                    value: 'projectPrice',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'CURRENT BALANCE TO BILL',
                    value: 'balanceToBill',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
                {
                    text: 'BASE BALANCE TO BILL',
                    value: 'baseBalanceToBill',
                    align: 'center',
                    sortable: false,
                    width: 150,
                    class: 'gray-background',
                },
            ],
            projects: [],
            projectToFilter: null,
            projectManagerToFilter: null,
            clientToFilter: null,
            forecastedPercentageToFilter: null,
            users: [],
            months: [
                'JAN',
                'FEB',
                'MAR',
                'APR',
                'MAY',
                'JUN',
                'JUL',
                'AUG',
                'SEP',
                'OCT',
                'NOV',
                'DEC',
            ],
            loading: false,
            quotes: [],
            viewType: 'month',
            headers: [],
            quoteHeaders: [],
            detailView: false,
            selectedProject: {},
            clients: [],
            currentDate: '',
        }
    },
    computed: {
        filteredForecasts() {
            let conditions = []

            if (this.projectToFilter) {
                conditions.push(this.filterByProject)
            }

            if (this.clientToFilter) {
                conditions.push(this.filterByClient)
            }

            if (this.projectManagerToFilter) {
                conditions.push(this.filterByProjectManager)
            }

            if (this.forecastedPercentageToFilter) {
                conditions.push(this.filterByForecastedPercentage)
            }

            if (conditions.length > 0) {
                return this.projects.filter(project => {
                    return conditions.every(condition => {
                        return condition(project)
                    })
                })
            }

            let forecasts = this.projects
            forecasts.sort(
                (a, b) => b.forecastedPercentage - a.forecastedPercentage
            )
            return forecasts
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getProjects({})
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.quotes = await API.getLiteQuotes()
            const {
                data: { clients },
            } = await API.getLiteClients()
            this.clients = clients

            let currentDate = new Date()
            this.currentDate = `${currentDate.getFullYear()}-${String(
                currentDate.getMonth() + 1
            ).padStart(2, '0')}`

            //set Quotes information for each project
            this.projects.forEach(project => {
                project.forecastQuotes = []
                if (project.quotes) {
                    project.quotes.forEach(quote => {
                        const projectQuote = this.quotes.find(
                            q => q.id == quote.split('/')[0]
                        )
                        if (projectQuote) {
                            project.forecastQuotes.push(projectQuote)
                        }
                    })
                }
            })

            //set forecast information for each quote
            this.projects.forEach(project => {
                if (project.forecast) {
                    project.forecast.forEach(forecast => {
                        const quoteIndex = project.forecastQuotes.findIndex(
                            quote => quote.id == forecast.id
                        )
                        if (quoteIndex > -1) {
                            const forecastCopy = _.cloneDeep(forecast)
                            delete forecastCopy.id
                            project.forecastQuotes[quoteIndex] = {
                                ...project.forecastQuotes[quoteIndex],
                                ...forecast,
                            }
                        }
                    })
                }
                project.forecastedPercentage = this.calculateForecastedPercentage(
                    project
                )
            })
            this.setHeaders()
            await this.getInvoices()
            this.showMonths()
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    updated() {
        this.onResize()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
            } = this.$refs
            this.height = containerHeight - 50
        },
        async getInvoices() {
            const invoices = await API.getInvoices({})
            this.filteredForecasts.forEach(project => {
                let projectInvoices = invoices.filter(
                    invoice => invoice.projectId == project.id
                )
                projectInvoices = projectInvoices.filter(
                    invoice =>
                        invoice.status != 'rejected' &&
                        invoice.status != 'inProgress' &&
                        invoice.billedOn
                )
                projectInvoices.forEach(invoice => {
                    invoice.date = this.formatDate2(invoice.billedOn._seconds)
                })

                let invoicedThisMonth = projectInvoices.filter(
                    invoice => invoice.date == this.currentDate
                )

                let invoicedThisYear = projectInvoices.filter(invoice =>
                    invoice.date.includes(this.currentDate)
                )

                let amountInvoicedThisMonth = 0
                let amountInvoicedThisYear = 0

                invoicedThisMonth.forEach(invoice => {
                    if (invoice.items) {
                        Object.keys(invoice.items).forEach(quoteId => {
                            amountInvoicedThisMonth += invoice.items[
                                quoteId
                            ].reduce(
                                (accumulator, item) =>
                                    accumulator +
                                    (Number(item.billedQty || 0) -
                                        Number(item.creditQty || 0)) *
                                        item.pricePerUnit,
                                0
                            )
                        })
                    }
                })

                invoicedThisYear.forEach(invoice => {
                    if (invoice.items) {
                        Object.keys(invoice.items).forEach(quoteId => {
                            amountInvoicedThisYear += invoice.items[
                                quoteId
                            ].reduce(
                                (accumulator, item) =>
                                    accumulator +
                                    (Number(item.billedQty || 0) -
                                        Number(item.creditQty || 0)) *
                                        item.pricePerUnit,
                                0
                            )
                        })
                    }
                })

                project.invoicedThisMonth = amountInvoicedThisMonth
                project.invoicedThisYear = amountInvoicedThisYear
            })
        },
        formatDate2(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM')}`
        },
        setHeaders() {
            const currentMonth = this.months[new Date().getMonth()]
            const currentYear = new Date().getFullYear()
            const invoicedHeaderText = `INVOICED IN ${currentMonth}`
            const invoicedHeaderTextYear = `INVOICED IN ${currentYear}`
            const vsHeaderText = `ACTUAL VS FORECASTED (${currentMonth})`
            this.originalHeaders.push(
                ...[
                    {
                        text: invoicedHeaderText,
                        value: 'invoicedThisMonth',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                        type: 'month',
                    },
                    {
                        text: invoicedHeaderTextYear,
                        value: 'invoicedThisYear',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                        type: 'year',
                    },
                    {
                        text: vsHeaderText,
                        value: 'actualVsForecasted',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                    },
                    {
                        text: 'FORECASTED PERCENTAGE',
                        value: 'forecastedPercentage',
                        align: 'center',
                        sortable: false,
                        width: 150,
                        class: 'gray-background',
                    },
                ]
            )

            this.filteredForecasts.forEach(project => {
                if (project.forecastQuotes) {
                    project.forecastQuotes.forEach(quote => {
                        if (quote.forecast) {
                            quote.forecast.forEach(entry => {
                                if (entry.date) {
                                    this.dateToShow.push(entry.date)
                                }
                            })
                        }
                    })
                }
            })
            this.dateToShow = [...new Set(this.dateToShow.sort())]
            const maxDate = this.dateToShow[this.dateToShow.length - 1]
            this.dateToShow = this.monthsBetweenDates(
                moment(this.currentDate),
                moment(maxDate)
            )

            let yearClasses = {}
            let toggleClass = true

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const monthIndex = Number(dateParts[1]) - 1
                const year = dateParts[0]
                const dateText = `${this.months[monthIndex]}-${year}`

                if (!yearClasses[year]) {
                    yearClasses[year] = toggleClass
                        ? 'dark-gray-background'
                        : 'gray-background'
                    toggleClass = !toggleClass
                }

                this.originalHeaders.push({
                    text: dateText,
                    value: 'date',
                    align: 'center',
                    sortable: false,
                    width: 100,
                    type: 'month',
                    class: yearClasses[year],
                    month: date,
                })
            })

            this.dateToShow.forEach(date => {
                const dateParts = date.split('-')
                const year = dateParts[0]
                const existent = this.originalHeaders.find(h => h.year == year)
                if (!existent) {
                    this.originalHeaders.push({
                        text: year,
                        value: 'dateYear',
                        align: 'center',
                        sortable: false,
                        width: 100,
                        type: 'year',
                        class: yearClasses[year],
                        year,
                    })
                }
            })
        },
        filterByProject(item) {
            return (
                item.name &&
                item.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        filterByClient(item) {
            return (
                item.client &&
                this.setClientName(item.client)
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            )
        },
        filterByProjectManager(item) {
            return (
                item.projectManager &&
                this.setUserName(item.projectManager)
                    .toLowerCase()
                    .includes(this.projectManagerToFilter.toLowerCase())
            )
        },
        filterByForecastedPercentage(item) {
            return item.forecastedPercentage
                .toString()
                .toLowerCase()
                .includes(
                    this.forecastedPercentageToFilter.toString().toLowerCase()
                )
        },
        showMonths() {
            this.headers = this.originalHeaders.filter(h => h.type != 'year')
            this.viewType = 'month'
        },
        showYears() {
            this.headers = this.originalHeaders.filter(h => h.type != 'month')
            this.viewType = 'year'
        },
        monthsBetweenDates(startDate, endDate) {
            let datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM'))
                datesObject[currentDay.format('YYYY-MM')] = 0
                currentDay.add(1, 'month')
            }
            return dates
        },
        setUserName(userId) {
            let userName = ''
            const user = this.users.find(u => u.id == userId)
            if (user) {
                userName = user.name
            }
            return userName
        },
        setClientName(clientId) {
            let clientName = ''
            const client = this.clients.find(c => c.id == clientId)
            if (client) {
                clientName = client.name
            }
            return clientName
        },
        setLastUpdate(item) {
            let lastUpdate = { _seconds: 0, _nanoseconds: 0 }
            item.forecastQuotes.forEach(forecast => {
                if (
                    forecast.updatedOn &&
                    forecast.updatedOn._seconds > lastUpdate._seconds
                ) {
                    lastUpdate = forecast.updatedOn
                }
            })
            return lastUpdate._seconds > 0
                ? this.formatDate(lastUpdate._seconds)
                : '-'
        },
        setProjectBalanceToBill(item) {
            let totalBaseB2B = 0
            item.forecastQuotes.forEach(forecast => {
                if (forecast.balanceToBill) {
                    totalBaseB2B += Number(forecast.balanceToBill)
                }
            })
            return totalBaseB2B
        },
        formatCurrency(currency, value) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 0,
            }).format(value)
        },
        calculateForecastedPercentage(item) {
            let forecastedAmount = 0
            if (item.forecastQuotes) {
                item.forecastQuotes.forEach(quote => {
                    if (quote.forecast) {
                        quote.forecast.forEach(entry => {
                            if (
                                moment(entry.date).isSameOrAfter(
                                    this.currentDate
                                )
                            ) {
                                forecastedAmount += Number(entry.amount)
                            }
                        })
                    }
                })
            }

            const projectBalanceToBill = this.setProjectBalanceToBill(item)
            return projectBalanceToBill > 0
                ? ((forecastedAmount * 100) / projectBalanceToBill).toFixed(1)
                : 0
        },
        totalPerDate(date) {
            let totalInDate = { USD: 0, COP: 0 }
            this.filteredForecasts.forEach(project => {
                if (project.forecastQuotes) {
                    project.forecastQuotes.forEach(quote => {
                        if (quote.forecast) {
                            quote.forecast.forEach(entry => {
                                if (date.includes('-')) {
                                    if (entry.date == date) {
                                        totalInDate[project.currency] += Number(
                                            entry.amount
                                        )
                                    }
                                } else {
                                    if (
                                        entry.date &&
                                        entry.date.includes(date) &&
                                        moment(entry.date).isSameOrAfter(
                                            this.currentDate
                                        )
                                    ) {
                                        totalInDate[project.currency] += Number(
                                            entry.amount
                                        )
                                    }
                                }
                            })
                        }
                    })
                }
            })
            const currencyCOP = this.formatCurrency('COP', totalInDate.COP)
            const currencyUSD = this.formatCurrency('USD', totalInDate.USD)
            return currencyUSD + ' - ' + currencyCOP
        },
        totalProjectsPrice() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )
            const totalUSD = usdProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.price) || 0,
                0
            )
            const totalCOP = copProjects.reduce(
                (accumulator, project) =>
                    accumulator + Number(project.price) || 0,
                0
            )
            return (
                this.formatCurrency('USD', totalUSD) +
                ' - ' +
                this.formatCurrency('COP', totalCOP)
            )
        },
        totalProjectsB2B() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )
            const totalUSD = usdProjects.reduce(
                (accumulator, project) =>
                    accumulator +
                    (Number(project.price || 0) -
                        Number(project.totalInvoiced || 0)),
                0
            )
            const totalCOP = copProjects.reduce(
                (accumulator, project) =>
                    accumulator +
                    (Number(project.price || 0) -
                        Number(project.totalInvoiced || 0)),
                0
            )
            return (
                this.formatCurrency('USD', totalUSD) +
                ' - ' +
                this.formatCurrency('COP', totalCOP)
            )
        },

        totalProjectsBaseB2B() {
            const usdProjects = this.filteredForecasts.filter(
                project => project.currency == 'USD'
            )
            const copProjects = this.filteredForecasts.filter(
                project => project.currency == 'COP'
            )

            let totalCOP = 0
            let totalUSD = 0
            copProjects.forEach(project => {
                if (project.forecastQuotes) {
                    totalCOP += project.forecastQuotes.reduce(
                        (accumulator, quote) =>
                            accumulator + Number(quote.balanceToBill || 0),
                        0
                    )
                }
            })

            usdProjects.forEach(project => {
                if (project.forecastQuotes) {
                    totalUSD += project.forecastQuotes.reduce(
                        (accumulator, quote) =>
                            accumulator + Number(quote.balanceToBill || 0),
                        0
                    )
                }
            })

            return (
                this.formatCurrency('USD', totalUSD) +
                ' - ' +
                this.formatCurrency('COP', totalCOP)
            )
        },
        openProject(project) {
            this.selectedProject = _.cloneDeep(project)
            this.detailView = true
        },
        closeDetailView() {
            this.detailView = false
            this.selectedProject = {}
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('MMM DD YYYY')}`
        },
        getDatePercentage(item, date) {
            const balanceToBill = this.setProjectBalanceToBill(item)
            if (item.forecastQuotes) {
                if (date.includes('-')) {
                    const amount = this.getDateAmount(item, date)
                    return balanceToBill > 0
                        ? ((amount * 100) / balanceToBill).toFixed(1)
                        : 0.0
                } else {
                    const amount = this.getDateAmount(item, date)
                    return balanceToBill > 0
                        ? ((amount * 100) / balanceToBill).toFixed(1)
                        : 0.0
                }
            }
        },
        getDateAmount(item, date) {
            let totalAmount = 0
            if (item.forecastQuotes) {
                if (date.includes('-')) {
                    item.forecastQuotes.forEach(quote => {
                        if (quote.forecast) {
                            const entry = quote.forecast.find(
                                entry => entry.date == date
                            )
                            if (entry) {
                                totalAmount += Number(entry.amount || 0)
                            }
                        }
                    })
                } else {
                    item.forecastQuotes.forEach(quote => {
                        if (quote.forecast) {
                            const entries = quote.forecast.filter(
                                entry =>
                                    entry.date &&
                                    entry.date.includes(date) &&
                                    moment(entry.date).isSameOrAfter(
                                        this.currentDate
                                    )
                            )
                            totalAmount += entries.reduce(
                                (accumulator, entry) =>
                                    accumulator + Number(entry.amount) || 0,
                                0
                            )
                        }
                    })
                }
            }
            return totalAmount
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow-y: scroll;
}

.text-orange {
    color: orange;
    font-weight: bold;
}
.text-green {
    color: green;
    font-weight: bold;
}
.text-red {
    color: red;
    font-weight: bold;
}

.fixed-column {
    position: sticky;
    right: 0;
    z-index: 1;
}

.gray-background {
    background-color: #eee;
}

.white-background {
    background-color: white;
}

.dark-gray-background {
    background-color: #c3c3c3;
}

.custom-label .v-label {
    font-size: 12px;
}
</style>
